import React from 'react';
import Layout from '../components/layout';
import { device } from '../providers/theme';
import styled from 'styled-components';
import SEO from '../components/seo';

const Block = styled.div`
  max-width: 50%;
  padding: 0 5vw;
  @media ${device.tablet} {
    max-width: 100%;
  }
  a {
    font-weight: 400;
    text-decoration: underline;
  }
`;

const Title = styled.h2`
  margin: 62px 0 8px;
`;

const Info = styled.div`
  margin: 42px 0;
  p {
    margin: 4px 0;
  }
`;

function AboutPage() {
  return (
    <React.Fragment>
      <SEO title={'Om os'} description={'Læs vores historie om vinkammeret'} />
      <Layout title="Om os" withoutSeo>
        <Block>
          <Title>Find vin og champagne lige efter dine smagsløg</Title>
          <p>
            Det er ikke altid lige nemt, at finde ud af hvilken vin man
            egentligt har lyst til. Det kan også være, at man blot vil prøve
            noget helt nyt. Med vinkammeret, har du nu et meget større udvalg,
            og samtidig muligheden for, at læse om den enkelte vin og hvor du
            kan købe vinen henne.
            <br />
            <br /> Hos Vinkammeret er der et stort og varierende udvalg samlet
            fra en håndfuld af landets vinforhandlere, der er med til at dele
            udvalget af sine rødvin, hvidvin, rosé, champagne og mousserende vin
            og også deres dessertvine.
          </p>
        </Block>
      </Layout>
    </React.Fragment>
  );
}

export default AboutPage;
